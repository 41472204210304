import React, { useState, useEffect } from "react";
import OrderCard from "../../components/Order";
import { useDispatch, useSelector } from "react-redux";
import { getOrders } from "../../store/slices/OrderSlice";
import Pusher from "pusher-js";

export default function (props) {
  const [ordersData, setOrderData] = useState("");
  const { user, loading } = useSelector((state) => state.reducer.UserSlice);
  const [visibility, setVisibility] = useState(true);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setVisibility(!document.hidden);
      console.log(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  // handle order state
  const updateOrderStatusById = (orderId, newStatus) => {
    setOrderData((prevOrders) =>
      prevOrders.map((order) =>
        order.id === orderId ? { ...order, order_status: newStatus } : order
      )
    );
  };

  const dispatch = useDispatch();

  function pusherClient() {
    let pusher = new Pusher(process.env.REACT_APP_PUSHER_API_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    });

    const channel = pusher.subscribe(`order-notification.${user?.merchant_id}`);
    channel.bind("App\\Events\\OrderNotification", function (data) {
      if (data?.order_status == "ACCEPTED") {
        dispatch(getOrders(user)).then((result) => {
          setOrderData(result.payload);
        });
      } else {
        updateOrderStatusById(data?.id, data?.order_status);
      }
    });

    // Event listener for connection state
    pusher.connection.bind("state_change", function (states) {
      // Handle reconnection
      if (
        states.previous === "connected" &&
        states.current === "disconnected"
      ) {
        // Attempt to reconnect
        pusher.connect();
      }
    });

    return () => {
      pusher.unsubscribe(`order-notification.${user?.merchant_id}`);
    };
  }

  useEffect(() => {
    dispatch(getOrders(user)).then((result) => {
      setOrderData(result.payload);
    });
    pusherClient();
  }, []);

  const pendingOrders =
    ordersData &&
    ordersData?.filter((order) => order.order_status === "ACCEPTED");
  const readyOrders =
    ordersData && ordersData?.filter((order) => order.order_status === "READY");

  return (
    <div className="wrapper">
      <div className="container pt-5 pb-5">
        <div className="mt-5">
          <h2>{user?.marchant}</h2>
        </div>
        <div className="row mt-5">
          <div className="col-lg-6 col-md-12">
            <h4 className="mb-4">In Progress</h4>
            {pendingOrders &&
              pendingOrders.map((order) => (
                <OrderCard key={order.id} order={order} />
              ))}
          </div>
          <div className="col-lg-6 col-md-12">
            <h4 className="mb-4">Ready for Pickup</h4>
            {readyOrders &&
              readyOrders.map((order) => (
                <OrderCard key={order.id} order={order} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
