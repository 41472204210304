import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getOrders = createAsyncThunk("orders", async (userData) => {
  const request = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/v1/internal/orders/get-active`,
    {merchant_id:userData?.merchant_id},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization:`Bearer ${userData?.authorisation_key}`
      },
    }
  );
  const response = await request.data;
  return response;
});

const orderSlice = createSlice({
  name: "order",
  initialState: {
    loading: false,
    data: [],
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrders.pending, (state) => {
        state.loading = true;
        state.data = null;
        state.error = null;
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(getOrders.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.error.message;
      });
  },
});

export default orderSlice.reducer;
