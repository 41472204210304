import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Auth from "./Modules/Auth";
import PickupMonitor from "./Modules/PickupMonitor";
import { useSelector } from "react-redux";

function App() {
  const user = useSelector((state) => state.reducer?.UserSlice.user);
  return (
    <BrowserRouter>
      {!user ? (
        <Routes>
          <Route path="/login" element={<Auth />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<PickupMonitor />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      )}
    </BrowserRouter>
  );
}

export default App;
