import React, { useState } from "react";
import testImage from "../../assets/test.png";

export default function ({order}) {
  return (
    <div className="card p-3 my-4 border-0">
      <div className="d-flex gap-3">
        <div className="image">
            <img src={order?.sales_channel_image} width="50" className="rounded" />
        </div>
        <div className="text">
            <h6 className="p-0 m-0">{order?.name}</h6>
            <p className="p-0 m-0">{order?.order_number}</p>
        </div>
      </div>
    </div>
  );
}
