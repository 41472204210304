import { configureStore } from "@reduxjs/toolkit";
import UserSlice from "./slices/UserSlice";
import OrderSlice from "./slices/OrderSlice";

import { combineReducers } from "@reduxjs/toolkit";


const reducer = combineReducers({
    UserSlice,
    OrderSlice
  })


const store = configureStore({
    reducer:{
        reducer
    }
})


export default store;