import React, { useState } from "react";
import mainLogo from "../../assets/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../store/slices/UserSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function (props) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  // redux state
  const { loading, error } = useSelector((state) => state.reducer.UserSlice);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogin = (e) => {
    e.preventDefault();
    let userCredentials = {
      username,
      password,
    };

    dispatch(loginUser(userCredentials)).then((result) => {
      if (result.payload) {
        setUsername("");
        setPassword("");
        navigate("/");
      } else {
        toast.error("Incorrect username or password!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    });
  };

  return (
    <div className="Auth-form-container d-flex">
      <form className="Auth-form col-10 col-xl-5" onSubmit={handleLogin}>
        <div>{<img src={mainLogo} width="220" />}</div>
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Sign into your account</h3>
          <div className="form-group mt-3">
            <input
              type="text"
              className="form-control mt-1 p-3"
              placeholder="Username"
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="form-group mt-3">
            <input
              type="password"
              className="form-control mt-1 p-3"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button type="submit" className="btn btn-primary">
              {loading ? "Loading..." : "Login"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
