// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.App {
  background-color: white;
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.Auth-form-title {
  margin: 1em 0em 2em 0em;
  font-size: 16px;
  font-weight: 400;
}

.btn-primary{
  background-color: #17669E !important;
  font-size: 14px;
  padding:10px;
  font-weight: 500;
}

.form-control{
  border-color: #CED4DA;
}

.wrapper{
  display: flex;
  background-color: #FAFAFA !important;
  min-height: 100vh;
  height: -moz-fit-content;
  height: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";AACA;EACE,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,uBAAuB;EACvB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,oCAAoC;EACpC,eAAe;EACf,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,oCAAoC;EACpC,iBAAiB;EACjB,wBAAmB;EAAnB,mBAAmB;AACrB","sourcesContent":["\n.App {\n  background-color: white;\n}\n\n.Auth-form-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100vw;\n  height: 100vh;\n}\n\n.Auth-form-title {\n  margin: 1em 0em 2em 0em;\n  font-size: 16px;\n  font-weight: 400;\n}\n\n.btn-primary{\n  background-color: #17669E !important;\n  font-size: 14px;\n  padding:10px;\n  font-weight: 500;\n}\n\n.form-control{\n  border-color: #CED4DA;\n}\n\n.wrapper{\n  display: flex;\n  background-color: #FAFAFA !important;\n  min-height: 100vh;\n  height: fit-content;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
